import PraxisLogo from './logos/Praxis-by-Emergn-black.svg';
import PoweredByStripe from './powered-by-stripe.svg';
import PoweredByStripeOutline from './powered-by-stripe-outline.svg';
import CreditCards from './credit-cards.svg';
import PathwayHeaderDiscovery from './pathway/header-discovery-light.svg';
import PathwayHeaderDelivery from './pathway/header-delivery-light.svg';
import PathwayHeaderStrategy from './pathway/header-strategy-light.svg';

export {
  PraxisLogo,
  PoweredByStripe,
  PoweredByStripeOutline,
  CreditCards,
  PathwayHeaderDiscovery,
  PathwayHeaderDelivery,
  PathwayHeaderStrategy,
};
